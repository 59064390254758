import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import FaqAccordion from "../../components/faq/faq-accordion";
import List from "../../components/custom-widgets/list";
import Icon from "../../components/custom-widgets/icon";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import Button from "../../components/custom-widgets/button";
import VimeoVideoFrame from "../../components/custom-widgets/vimeo-video-frame";

const VoiceActivatedBanking = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/voice-activated-banking/hero-voice-activated-banking-01-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/voice-activated-banking/hero-voice-activated-banking-01-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/voice-activated-banking/hero-voice-activated-banking-01-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/voice-activated-banking/hero-voice-activated-banking-01-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/voice-activated-banking/hero-voice-activated-banking-01-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/voice-activated-banking/hero-voice-activated-banking-01-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/voice-activated-banking/hero-voice-activated-banking-01-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const faqAccordionData = {
    id: "voice-activated-banking-faq-accordion",
    title: "Voice-Activated Banking FAQs",
    faqCategoryNames: ["Voice-Activated Banking"],
    showContactBar: false
  };

  const seoData = {
    title: "Voice-Activated Banking Technology",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Voice-Activated Banking Technology"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Learn about WaFd Bank's latest Voice-Activated Banking technology that uses your voice ID to verify who you are and allows you to access your financial information."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/voice-recognition-technology"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-voice-activated-banking-01-250.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "voice-activated-banking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Cheerful woman speaking on a cellphone.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Voice-Activated Banking"
          }
        },
        {
          id: 2,
          subheading: {
            class: "text-white",
            text: "Check your balance in just seconds!"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-btn-1",
            text: "Call Us at 800-324-9375",
            url: "tel:800-324-9375",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Voice-Activated Banking"
    }
  ];

  const vbVideoData = {
    class: "m-auto iframe w-100 border-radius-12",
    vimeoId: "936380940",
    minHeight: "250px",
    title: "How to Enroll in Voice Activated Banking at WaFd Bank"
  };
  const voiceBankingData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "<strong>Check Your Balance:</strong> No more waiting on hold; it takes less than a  minute"
      },
      {
        id: 2,
        text: "<strong>Transfer Funds:</strong> Transfer funds and more just by speaking"
      },
      {
        id: 3,
        text: "<strong>Time-Saving:</strong> Get your banking done in seconds"
      },
      {
        id: 4,
        text: "<strong>Convenient:</strong> Access your banking services anytime, anywhere, with just a call"
      },
      {
        id: 5,
        text: "<strong>Safe:</strong> Advanced voice-authentication technology ensures your accounts are safe and private"
      }
    ]
  };
  const getStartedData = {
    type: "ol",
    class: "bold-bullets mb-0",
    items: [
      {
        id: 1,
        text: `<strong>Enroll in Online Banking:</strong> If you are not already, you will need to enroll in <a id="enroll-online-banking-link" target="_blank" rel="noopener noreferrer" class="text-white" href="https://online.wafdbank.com/link/register?application=OLB">Online Banking</a> before you can set up Voice-Activated Banking.`
      },
      {
        id: 2,
        text: `<strong>Sign Up:</strong> Setting up Voice-Activated Banking is easy and only takes a couple of minutes. Call us today at <a href="tel:800-324-9375" id="get-started-step-voice-link-1" class="text-white" >800-324-9375</a>.`
      },
      {
        id: 3,
        text: `<strong>Bank with Your Voice:</strong> Start using your voice to manage your finances effortlessly—call <a href="tel:800-324-9375" id="get-started-step-voice-link-2" class="text-white" >800-324-9375</a>, 24 hours a day, and when prompted say, "my password is my voice." Tell the system what you'd like to do and you're on your way!`
      }
    ]
  };

  return (
    <SecondaryLanding>
      <HeroChevron {...heroChevronData} />
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Welcome to the Future of Banking with WaFd Bank's Voice-Activated Banking</h1>
        <h3 className="mb-0">
          WaFd Bank is proud to introduce our revolutionary voice banking solution&mdash;a faster, simpler, and more
          personal way to manage your finances. Say goodbye to waiting on hold and navigating through endless menu
          options. With our state-of-the-art technology, your voice is now the key to your banking needs.
        </h3>
      </section>
      <section className="bg-gray-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../images/voice-activated-banking-600.jpg"
                alt="Happy senior man talking on his cellphone."
                placeholder="blurred"
                loading="eager"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h2 className="font-weight-semi-bold text-success">Why Choose Voice-Activated Banking?</h2>
              <List {...voiceBankingData} accountName={"voice-activated-banking"} />
              <a
                className="text-decoration-none text-wrap"
                href="tel:800-324-9375"
                id="voice-activated-banking-contact-link"
              >
                <Icon lib="far" name="phone" class="mr-2" />
                Call us at 800-324-9375 to sign up for Voice-Activated Banking
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <h2 className="font-weight-semi-bold text-success">Is Voice-Activated Banking Safe?</h2>
            <p>
              Voice-Activated Banking is a service that uses your unique voice to verify who you are. Your voiceprint
              (like your fingerprint) is an added layer of protection to safeguard you against fraud.
            </p>
            <p>
              One of the most powerful benefits of Voice-Activated Banking is that it prevents fraud. Once you're
              enrolled, no one else can call the bank trying to impersonate you&mdash;we've got your voice on file, and
              the call must come from the cell phone registered to you.
            </p>
            <Link
              to="/blog/security-privacy/how-voice-activated-banking-works"
              id="how-voice-activated-banking-works-link"
              className="text-decoration-none mb-2 mb-md-0"
            >
              How Voice-Activated Banking Works
              <Icon name="arrow-right" class="ml-1" />
            </Link>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../images/thumbnail-voice-banking-safety-032024.jpg"
              alt="Businesswoman smiling while on her mobile phone in a crowded office. "
              placeholder="blurred"
              loading="eager"
              quality="100"
            />
          </div>
        </div>
      </section>
      <section className="bg-success text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <VimeoVideoFrame {...vbVideoData} />
            </div>
            <div className="col-md-6">
              <h3 className="font-weight-bold">How to Get Started</h3>
              <List {...getStartedData} accountName={"get-started"} />
            </div>
          </div>
        </div>
      </section>
      <FaqAccordion {...faqAccordionData} />
      <section id="contact-us-section" className="bg-success text-white">
        <div className="container">
          <h3 className="font-weight-semi-bold">Contact Us</h3>
          <div className="row">
            <div className="col-md-6">
              <h5>We're here to help you make the switch to effortless, Voice-Activated Banking.</h5>
            </div>
            <div className="col-md-6">
              <Button
                id="contact-us-cta-btn"
                type="anchor"
                class="btn-white"
                showIcon={false}
                url="tel:800-324-9375"
                text="Call us at 800-324-9375"
              />
            </div>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default VoiceActivatedBanking;
